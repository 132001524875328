/**
 * External Dependencies
 */
import React from "react"
import { graphql } from "gatsby"

/**
 * Internal Dependencies
 */
import Layout from "../components/Layout"
import MoreStories from "../components/MoreStories"
import SEO from "../components/SEO"
import parseContent from "../utils/parse-content"
import { prepareMoreStoriesNodes } from "../utils/more-stories"

const ExploreTemplate = ({
  data,
  data: { post },
  pageContext: { localeSettings, moreStoriesPostIds, urlTranslations },
}) => {
  const relatedPosts = prepareMoreStoriesNodes(moreStoriesPostIds, data, urlTranslations)

  return (
    <Layout settings={localeSettings} post={post}>
      <SEO title={post.title} description={post.excerpt} seo={post.seo} />

      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        {!!post.content && (
          <section itemProp="articleBody">{parseContent(post.content)}</section>
        )}

        {relatedPosts.length > 0 && <MoreStories stories={relatedPosts} />}
      </article>
    </Layout>
  )
}

export default ExploreTemplate

export const pageQuery = graphql`
  query ExplorePostBySlug(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $slug: String!
    $locale: String!
    $moreStoriesPostIds: [Int!]
  ) {
    # selecting the current post by slug
    post: wpExplore(
      slug: { eq: $slug }
      language: { locale: { eq: $locale } }
    ) {
      id
      uri
      slug
      guid
      date(formatString: "MMMM DD, YYYY")
      title
      backgroundMedia
      excerpt
      content
      seo {
        metaDesc
        metaKeywords
        opengraphDescription
        opengraphImage {
          uri
          localFile {
            publicURL
          }
        }
        opengraphSiteName
        opengraphTitle
        opengraphType
        title
        twitterDescription
        twitterImage {
          uri
          localFile {
            publicURL
          }
        }
        twitterTitle
      }
    }
    allWpInnovate(filter: { databaseId: { in: $moreStoriesPostIds } }) {
      nodes {
        databaseId
        slug
        uri
        nodeType
        title
        content
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              publicURL
              childImageSharp {
                fluid(quality: 90, maxWidth: 780) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        mobileFeaturedImageUrl
        tags {
          nodes {
            name
          }
        }
      }
    }
    allWpInspire(filter: { databaseId: { in: $moreStoriesPostIds } }) {
      nodes {
        databaseId
        slug
        uri
        nodeType
        title
        content
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              publicURL
              childImageSharp {
                fluid(quality: 90, maxWidth: 780) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        mobileFeaturedImageUrl
        tags {
          nodes {
            name
          }
        }
      }
    }
    allWpExplore(filter: { databaseId: { in: $moreStoriesPostIds } }) {
      nodes {
        databaseId
        slug
        uri
        nodeType
        title
        content
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              publicURL
              childImageSharp {
                fluid(quality: 90, maxWidth: 780) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        mobileFeaturedImageUrl
        tags {
          nodes {
            name
          }
        }
      }
    }
    allWpDrive(filter: { databaseId: { in: $moreStoriesPostIds } }) {
      nodes {
        databaseId
        slug
        uri
        nodeType
        title
        content
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              publicURL
              childImageSharp {
                fluid(quality: 90, maxWidth: 780) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        mobileFeaturedImageUrl
        tags {
          nodes {
            name
          }
        }
      }
    }
    allWpShortStory(filter: { databaseId: { in: $moreStoriesPostIds } }) {
      nodes {
        databaseId
        slug
        uri
        nodeType
        title
        content
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              publicURL
              childImageSharp {
                fluid(quality: 90, maxWidth: 780) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        mobileFeaturedImageUrl
        tags {
          nodes {
            name
          }
        }
      }
    }
  }
`
